// Warframe.com Home Theme
// Used on front page and news pages.

@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

$COLOR_BUTTON_BLUE: #356581;
$COLOR_BUTTON_BLUE_HOVER: #598299;

$IMAGE_DIR: "/images/home/";

.MainPageContainer {
    overflow: hidden;
}
.Masthead-inner {
    @include CONTENT_CONTRAST_FILTER;
}
.SectionBackground {
    @include FULL_COVER;
}

// Home page
.HomeMastheadVideo {
    @include FULL_COVER;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @include breakpoint-mobile-only {
        bottom: auto;
        height: 80vw;
    }
    @include breakpoint-tablet-only {
        bottom: auto;
        height: 56.25vw;
    }
}

.HomeMastheadUpdatesRow {
    @include breakpoint-mobile-only {
        padding: $CONTENT_GUTTER_MOBILE 0;
    }
    @include breakpoint-tablet-up {
        padding: $CONTENT_GUTTER_TABLET 0;
    }

    &-history {
        --color-link: #{$COLOR_INVERTED_TEXT};
        --color-link-hover: #{$COLOR_INVERTED_TEXT};
        text-decoration: underline;
    }
}

.HomeNewsSection {
    z-index: 2;
    margin-top: $SPACE_LARGE;

    @include breakpoint-mobile-only {
        padding-bottom: 76vw;
    }
    @include breakpoint-tablet-only {
        padding-bottom: 50vw;
    }
    @include breakpoint-desktop-up {
        margin-top: -$SPACE_LARGE;
    }
}

.HomeNewsSectionContent {
    padding-bottom: $SPACE_HUGE;

    @include breakpoint-tablet-up {
        padding-bottom: $SPACE_LARGER;
    }
}

.HomeNewsGrid {
    display: grid;
    gap: $SPACE_MEDIUM;

    @include breakpoint-mobile-only {
        grid-template-columns: 1fr;
    }
    @include breakpoint-tablet-only {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include breakpoint-desktop-up {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        width: $GRID_DESKTOP_TEN_COLUMNS;
        margin: 0 auto;
    }

    .HomeNewsTile {
        @include breakpoint-mobile-only {
        }
        @include breakpoint-tablet-only {
            &:nth-child(1) {
                grid-row: 1;
                grid-column: 1 / span 2;

                .HomeNewsTile-title {
                    font-size: $FONT_SIZE_H3;
                }
            }
            &:nth-child(2) {
                grid-row: 2;
                grid-column: 1;
            }
            &:nth-child(3) {
                grid-row: 2;
                grid-column: 2;
            }
        }
        @include breakpoint-desktop-up {
            &:nth-child(1) {
                grid-row: 1 / span 2;
                grid-column: 1 / span 2;
                aspect-ratio: auto;

                .HomeNewsTile-title {
                    font-size: $FONT_SIZE_H3_DESKTOP;
                }
            }
            &:nth-child(2) {
                grid-row: 1;
                grid-column: 3;
            }
            &:nth-child(3) {
                grid-row: 2;
                grid-column: 3;
            }
        }
    }

    .HomeNewsSnippet {
        @include breakpoint-tablet-only {
            grid-column: 1 / span 2;
        }

        @include breakpoint-tablet-down {
            padding-bottom: $SPACE_MEDIUM;
            &:not(:last-child) {
                border-bottom: solid 2px $COLOR_MEDIUM_GRAY;
            }
        }

        @include breakpoint-desktop-up {
            padding: $SPACE_MEDIUM;

            &:nth-of-type(3n),
            &:nth-of-type(3n-1) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: $SPACE_THIN;
                    background-color: $COLOR_MEDIUM_GRAY;
                }
            }
        }
    }
}

.HomeNewsTile {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, .5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 16/9;
    color: var(--color-news-tile-text, #{$COLOR_INVERTED_TEXT});
    --color-link: #{$COLOR_INVERTED_TEXT};
    --color-link-hover: #{$COLOR_LINK_HOVER};

    &-content {
        position: relative;
        padding: $SPACE_LARGE $SPACE_MEDIUM $SPACE_MEDIUM;
        background: linear-gradient(to top, rgba(0, 0, 0, .9), transparent);
    }

    &-date {

    }

    &-title {
        @include TYPOGRAPHY_H5;
    }

    &-description {

    }
}

.HomeNewsSnippet {
    position: relative;
    display: block;
    color: var(--color-news-snippet-title, #{$COLOR_TEXT});
    --color-link: #{$COLOR_TEXT};
    --color-link-hover: #{$COLOR_LINK_HOVER};

    @include LINK_NO_UNDERLINE;

    &-date {
        font-size: $FONT_SIZE_MIN;
        color: var(--color-news-snippet-text, #{$COLOR_DARK_GRAY});
    }

    &-title {
        @include TYPOGRAPHY_H5;
    }

    &-description {
        font-size: $FONT_SIZE_SUB;
        color: var(--color-news-snippet-text, #{$COLOR_DARK_GRAY});
    }
}

.HomeNewsButtonRow {
    text-align: center;
    padding-top: $SPACE_MEDIUM;
    padding-bottom: $SPACE_LARGE;
}

.HomeNewsButton {

}

.PrimeAccessSection {
    z-index: 3;
    background-color: $COLOR_INVERTED_BACKGROUND;

    .Section-wrapper {
        overflow: visible;
    }
}
.PrimeAccessDesktopBackground,
.PrimeResurgenceDesktopBackground {
    @include FULL_COVER;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include breakpoint-tablet-down {
        display: none;
    }
}

.PrimeAccessSectionContent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: $SPACE_LARGER;

    @include breakpoint-desktop-up {
        padding-top: $SPACE_LARGER;
        min-height: 625px;
    }

    @include breakpoint-tablet-only {
        padding-bottom: 96px
    }
}

.PrimeAccessPanel {
    position: relative;
    border: solid 2px;
    border-image: radial-gradient(ellipse at center, #FFB50F 0%, rgba(255, 255, 255, 0.1) 100%) 2;
    background: rgba(0, 0, 0, .5) linear-gradient(301.33deg, transparent 21.7%, rgba(221, 180, 88, 0.15) 34.1%, transparent 63.47%);
    color: $COLOR_INVERTED_TEXT;
    padding: $SPACE_LARGE;
    margin-right: auto;

    @include breakpoint-tablet-down {
        display: none;
    }

    @include breakpoint-tablet-up {
        width: 648px;
        padding: $SPACE_LARGER;
    }

    &-headerImage {
        position: absolute;
        top: -1.5rem;
        left: -1.5rem;
        width: 300px;
        height: 50px;
        @include breakpoint-tablet-up {
            width: 413px;
            height: 69px;
        }
    }

    &-title {
        @include TYPOGRAPHY_H1_ALTERNATE;
    }

    &-description {
        margin: 1rem 0;
    }

    &-buttonRow {
        margin: 1rem 0;
    }

    &-whatRow {

    }

    &-whatLink {
        --color-link: #{$COLOR_INVERTED_TEXT};
        --color-link-hover: #{$COLOR_INVERTED_TEXT};
        text-decoration: underline;
    }
}

.PrimeAccessWarframeArt {
    display: none;
    position: absolute;
    bottom: 0;
    width: 65%;
    pointer-events: none;

    @include breakpoint-desktop-up {
        display: block;
        left: calc(50% - 80px);
    }
    @include breakpoint-wide-up {
        left: calc(50% - 160px);
        width: 1060px;
    }
}

.PrimeAccessTabletMobilePanel {
    position: relative;
    background: rgba(0, 0, 0, 0);
    color: $COLOR_INVERTED_TEXT;
    padding: 0px $SPACE_LARGE;
    margin: auto;
    text-align: center;

    @include breakpoint-tablet-only {
        height: 268px;
        width: 648px;
        padding: 0px $SPACE_LARGER;
    }

    @include breakpoint-desktop-up {
        display: none;
    }

    &-headerImage {
        position: relative;
        top: -1.5rem;
        margin: auto;
        width: 252px;
        height: 43px;
        @include breakpoint-tablet-up {
            width: 305px;
            height: 51px;
        }
    }

    &-title {
        @include TYPOGRAPHY_H1_ALTERNATE;
        @include breakpoint-mobile-only {
            text-align: left;
        }
    }

    &-description {
        margin: 1rem 0;
        @include breakpoint-mobile-only {
            text-align: left;
        }
    }

    &-buttonRow {
        margin: 1rem 0;
        @include breakpoint-mobile-only {
            text-align: left;
        }
    }

    &-whatRow {
        @include breakpoint-mobile-only {
            text-align: left;
        }
    }

    &-whatLink {
        --color-link: #{$COLOR_INVERTED_TEXT};
        --color-link-hover: #{$COLOR_INVERTED_TEXT};
        text-decoration: underline;

        @include breakpoint-mobile-only {
            text-align: left;
        }
    }

    &-buyButtonTablet {
        @include breakpoint-mobile-only {
            // display: none;
        }
    }

    &-buyButtonMobile {
        @include breakpoint-tablet-only {
            // display: none;
        }
    }
}

.PrimeAccessWarframeArtMobile {
    display: none;

    position: absolute;
    bottom: 99%;
    pointer-events: none;

    left: -10px;
    width: 120vw;

    @media (min-width: 361px) {
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
    }

    @include breakpoint-mobile-only {
        display: block;
    }
}

.PrimeAccessWarframeArtTablet {
    display: none;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;

    position: absolute;
    bottom: 99%;
    pointer-events: none;

    @include breakpoint-tablet-only {
        display: block;
    }
}

// Platinum & Packs section
.PlatinumPacksSectionContent {
    padding-top: 40px;
    padding-bottom: 200px;

    @include breakpoint-tablet-down {
        padding-top: 64px;
        padding-bottom: 104px;
    }
    @include breakpoint-desktop-up {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.PlatinumPacksMobileHeader {
    position: relative;

    @include breakpoint-tablet-up {
        display: none;
    }

    &-image {
        width: 100%;
        vertical-align: bottom;
    }
}

.PlatinumPacksPanel {
    position: relative;
    @include breakpoint-desktop-up {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: stretch;
    }

    &-art {
        position: relative;
        @include breakpoint-desktop-up {
            order: 2;
        }
    }
    &-artImage {
        vertical-align: bottom;
        aspect-ratio: 1467/848;
        width: 100%;
    }

    &-content {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        border: solid 2px;
        border-image: radial-gradient(ellipse at center, #356581 0%, rgba(255, 255, 255, 0) 100%) 2;
        background: linear-gradient(301.33deg, rgba(53, 101, 129, 0) 21.7%, rgba(53, 101, 129, 0.1) 34.1%, rgba(53, 101, 129, 0) 63.47%);
        padding: 48px;

        @include breakpoint-tablet-up {
        }
        @include breakpoint-desktop-up {
            order: 1;
        }
        @include breakpoint-wide-up {
            padding: 64px;
        }
    }

    &-platinumImage {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(1rem, 1.5rem);
        width: 128px;
        height: 128px;
        z-index: 1;
        pointer-events: none;

        @include breakpoint-tablet-up {
            width: 180px;
            height: 180px;
            transform: translate(2rem, 2rem);
        }
        @include breakpoint-desktop-up {
            width: 180px;
            height: 180px;
            transform: translate(50%, 25%);
        }
    }

    &-pretitle {
        color: $COLOR_DARK_GRAY;
        margin-bottom: $SPACE_SMALL;
    }

    &-title {
        @include TYPOGRAPHY_H3_ALTERNATE;
    }
    &-title {
        line-height: 1.3;
    }

    &-description {
        margin: 1rem 0;
    }

    &-buttonRow {
        margin-top: 1rem;
    }

    &-shopButton {
    }
}

// Prime Vault/Resurgence section
.PrimeResurgenceSection, .PrimeResurgenceBonusSection {
    z-index: 1;
    background-color: $COLOR_INVERTED_BACKGROUND;

    .Section-wrapper {
        overflow: visible;
    }

    .Section-content {
        z-index: 1;
    }

    &:after {
        content: '';
        @include FULL_COVER;
    }
}

.PrimeResurgenceSection {
    &:after {
        background-color: $COLOR_BACKGROUND;
        background-repeat: no-repeat;
        @include breakpoint-desktop-up {
            background: linear-gradient(313deg, rgba(0, 0, 0, 0.7) 44.81%, rgba(0, 0, 0, 0) 68.05%);
        }
    }
}

.PrimeResurgenceSectionContent, .PrimeResurgenceBonusSectionContent {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 128px;
    padding-bottom: $SPACE_LARGER;

    @include breakpoint-tablet-down {
        padding-top: $SPACE_LARGER;
    }

    @include breakpoint-desktop-up {
        min-height: 625px;
    }

    @media(max-width: 360px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.PrimeResurgenceSection {
    &.hasBonus {
        .PrimeResurgenceSectionContent {
            padding-bottom: 48px;

            @include breakpoint-desktop-up {
                padding-bottom: 128px;
            }
        }
    }
}

.PrimeResurgenceBonusSectionContent {
    padding-top: 60px;

    @include breakpoint-desktop-up {
        min-height: 284px;
    }
}

.PrimeResurgencePanel {
    position: relative;
    border: solid 2px;
    border-image: radial-gradient(ellipse at center, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) 2;
    background: linear-gradient(301.33deg, rgba(221, 180, 88, 0) 21.7%, rgba(221, 180, 88, 0.15) 34.1%, rgba(221, 180, 88, 0) 63.47%);
    color: $COLOR_INVERTED_TEXT;
    padding: $SPACE_LARGE;
    margin-left: auto;

    @include breakpoint-tablet-down {
        display: none;
    }

    @include breakpoint-tablet-up {
        padding: $SPACE_LARGER;
    }

    @include breakpoint-tablet-only {
        width: 384px;
    }

    @media(min-width: 1200px) {
        width: 600px;
    }

    @media(min-width: 1800px) {
        width: 700px;
        left: 100px;
    }

    &-headerImage {
        position: absolute;
        top: -1.5rem;
    }

    &-title {
        @include TYPOGRAPHY_H3_ALTERNATE;
    }

    &-description {
        margin: 1rem 0;
    }

    &-buttonRow {
        margin: 1rem 0;
    }
    &-learnButton {
        --color-button-border-hover: #{$COLOR_INVERTED_TEXT};
    }

    &-whatRow {

    }

    &-whatLink {
        --color-link: #{$COLOR_INVERTED_TEXT};
        --color-link-hover: #{$COLOR_INVERTED_TEXT};
        text-decoration: underline;
    }
}

.PrimeResurgenceTabletMobilePanel {
    position: relative;
    color: $COLOR_TEXT;
    padding: 0 $SPACE_LARGE;
    margin: auto;

    @include breakpoint-desktop-up {
        display: none;
    }

    @include breakpoint-tablet-only {
        width: 528px;
        text-align: center;
    }

    @media(min-width: 1200px) {
        width: 600px;
    }

    &-headerImage {
        display: none;
        position: relative;
        margin: auto;
        width: 315px;
        z-index: 100;
        @include breakpoint-mobile-only {
            width: 250px;
        }
        @include breakpoint-tablet-down {
            display: block;
        }
    }

    &-title {
        margin: auto;
        @include TYPOGRAPHY_H1_ALTERNATE;
    }

    &-description {
        margin: 1rem 0;
    }

    &-buttonRow {
        margin: 1rem 0;
    }

    &-whatRow {

    }

    &-whatLink {
        --color-link: #{$COLOR_TEXT};
        --color-link-hover: #{$COLOR_TEXT};
        text-decoration: underline;
    }
}

.PrimeResurgencePanel {
    &-headerImage {
        right: 50%;
        transform: translateX(50%);
        width: 315px;
    }
}

.PrimeResurgenceWarframeArtMobile {
    position: relative;
    pointer-events: none;
    z-index: 1;

    @include breakpoint-tablet-up {
        display: none;
    }
}

.PrimeResurgenceWarframeArtMobile {
    bottom: 1vh;

    @media (min-width: 361px) {
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
    }
}

.PrimeResurgenceWarframeArtTablet {
    display: none;
    position: relative;
    pointer-events: none;
    width: 100vw;
    z-index: 1;
    bottom: 2vh;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;

    @include breakpoint-tablet-only {
        display: block;
    }
}

.PrimeResurgenceWarframeArt2Mobile {
    position: absolute;
    right: -16px;
    bottom: calc(100% - 80px);
    width: 297px;
    height: 318px;
    pointer-events: none;
    z-index: -2;

    @include breakpoint-tablet-up {
        display: none;
    }
}

.PrimeResurgenceWarframeArt {
    display: none;
    position: absolute;
    right: 50%;
    bottom: 0;
    width: 813px;
    pointer-events: none;
    z-index: 1;

    @include breakpoint-desktop-only {
        display: block;
    }
}

.PrimeResurgenceWarframeArtWide {
    display: none;
    position: absolute;
    right: 704px;
    bottom: 0;
    width: 1021px;
    pointer-events: none;
    z-index: 1;

    @include breakpoint-wide-up {
        display: block;
    }
    @include breakpoint-wide-up {
        //right: 704px;
    }
}

.PrimeResurgenceWarframeArt2 {
    display: none;
    position: absolute;
    right: 704px;
    //right: calc(5vw + 575px);
    bottom: 0;
    width: 550px;
    pointer-events: none;

    @include breakpoint-desktop-up {
        display: block;
    }
    @include breakpoint-tablet-only {
        //right: calc(5vw + 570px);
        width: 475px;
    }
    @include breakpoint-wide-up {
        //right: 704px;
    }
}

.PrimeResurgenceBonusSection {
    padding-top: 0;

    @include breakpoint-tablet-down {
        background-color: $COLOR_BACKGROUND;
        .PrimeResurgenceBonusSectionContent {
            padding-top: 10px;
        }
    }

    .PrimeResurgenceBonusPanel {
        color: white;

        @include breakpoint-tablet-down {
            display: none;
        }

        p {
            margin: 0;
            text-transform: capitalize;
            font-size: 24px;
        }
    }

    .PrimeResurgenceWarframeArt {
        left: 600px;
        right: unset;
        width: 600px;
    }

    .PrimeResurgenceWarframeArtWide {
        left: 895px;
        right: unset;
        width: 620px;
    }
    
    .PrimeResurgenceTabletMobilePanel {
        p {
            margin: 0;
        }
    }

    .primeresurgence-tabletmobile-buttons {
        margin-top: 10px;
    }
}

// Merchandise section
.MerchSectionContent {
    padding-top: $SPACE_LARGER;
    padding-bottom: $SPACE_LARGER;
}

.MerchPanel {
    position: relative;
    border: solid 2px;
    border-image: radial-gradient(ellipse at center, #356581 0%, rgba(255, 255, 255, 0) 100%) 2;
    background: linear-gradient(301.33deg, rgba(53, 101, 129, 0) 21.7%, rgba(53, 101, 129, 0.1) 34.1%, rgba(53, 101, 129, 0) 63.47%);
    padding: $SPACE_LARGE $SPACE_LARGE 265px;
    min-height: 275px;

    @include INSET_CONTENT;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url(#{$IMAGE_DIR}wf-bg-logo-2x.png);
        background-repeat: no-repeat;
        background-position: 25% 50%;
        background-size: 200% auto;
        @include breakpoint-tablet-up {
            background-position: left;
            background-size: auto 220%;
        }
    }

    @include breakpoint-tablet-only {
        padding: $SPACE_LARGE 360px $SPACE_LARGE $SPACE_LARGE;
    }
    @include breakpoint-desktop-up {
        padding: $SPACE_LARGER 640px $SPACE_LARGER $SPACE_LARGER;
    }

    &-pretitle {
        text-transform: uppercase;
        color: $COLOR_DARK_GRAY;
        margin-bottom: $SPACE_SMALL;
    }

    &-title {
        @include TYPOGRAPHY_H3_ALTERNATE;
    }
    &-title {
        line-height: 1;
    }

    &-buttonRow {
        margin-top: 1rem;
    }

    &-shopButton {
    }
}

.MerchPanelArt {
    &-image {
        position: absolute;
        bottom: 0;
    }

    &-desktop {
        @include breakpoint-tablet-down {
            display: none;
        }
    }

    &-mobile {
        @include breakpoint-desktop-up {
            display: none;
        }
    }

    &-collection {
        width: 530px;
        right: 60px;
        bottom: 0;

        @include breakpoint-tablet-down {
            width: 400px;
            right: 0;
            bottom: 0;
        }

        @include breakpoint-mobile-only {
            width: 380px;
            right: -20px;
            bottom: 0;
        }
    }
}

.Button--animatedBlue {
    --color-button-background: #{darken($COLOR_BUTTON_BLUE, 10%)};
    --color-button-background-hover: #{$COLOR_BUTTON_BLUE};
}

.ArticleContainer {
    padding-top: $SPACE_LARGE;
    padding-bottom: $SPACE_HUGE;
    display: grid;
    grid-template-columns: 1fr;
    gap: $SPACE_MEDIUM;
}

.ArticleHeader {
    display: grid;
    grid-template-columns: auto auto;
    border-bottom: solid 1px $COLOR_DIVIDER;

    &-title {
        grid-column: 1 / span 2;
        justify-self: start;
        color: $COLOR_ACCENT;
    }

    &-description {
        grid-column: 1 / span 2;
        justify-self: start;
    }

    &-date {
        grid-column: 1;
        justify-self: start;
        align-self: center;
    }

    &-socials {
        grid-column: 2;
        justify-self: end;
        align-self: center;
    }
}

.ArticleBody {
    display: grid;
    grid-template-columns: 1fr;
    gap: $SPACE_LARGE;
    @include breakpoint-tablet-up {
        grid-template-columns: minmax(0, 3fr);
        grid-auto-columns: minmax(0, 1fr);
    }

    &-content {

    }

    &-related {
        @include breakpoint-tablet-up {
            grid-column: 2;
        }
    }

    .post-image {
        width: 100%;
        aspect-ratio: 16/9;
    }
}

.NewsPageSearchBar {
    margin-top: $SPACE_HUGE;
}

.NewsPostSidebar {
    display: grid;
    grid-template-columns: 1fr;
    align-self: start;
    gap: $SPACE_MEDIUM;

    &-header {
        color: $COLOR_INVERTED_TEXT;
        background-color: $COLOR_INVERTED_BACKGROUND;
        padding: $SPACE_MEDIUM;
    }

    &-posts {
        display: grid;
        grid-template-columns: 1fr;
        align-items: stretch;
        gap: $SPACE_LARGE;

        @include breakpoint-tablet-up {
            gap: $SPACE_MEDIUM;

            .NewsCard-title {
                @include TYPOGRAPHY_H6;
            }
        }
    }
}

.NewsCardList {
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    gap: $SPACE_LARGE;
    margin-top: $SPACE_LARGER;

    @include breakpoint-tablet-up {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.NewsCard {
    position: relative;
    text-align: left;

    .Card-content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr auto auto;
    }

    &-title {
        @include TYPOGRAPHY_H3;

        a {
            --color-link: #{$COLOR_TEXT};
            --color-link-hover: #{$COLOR_TEXT};
        }
    }

    &-date {
        font-size: $FONT_SIZE_SUB;
        color: $COLOR_DARK_GRAY;
    }

    &-description {
        margin: 1rem 0;
    }

    &-tags {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        gap: $SPACE_SMALL;
        margin-top: 1rem;
    }
}

.PlatformTag {
    display: inline-block;
    @include BADGE_STYLES;
    background-color: $COLOR_PLATFORM_PC;
    color: $COLOR_INVERTED_TEXT;
    white-space: nowrap;

    &[data-platform="xbox"] {
        background-color: $COLOR_PLATFORM_XBOX;
    }

    &[data-platform="psn"] {
        background-color: $COLOR_PLATFORM_PLAYSTATION;
    }

    &[data-platform="switch"] {
        background-color: $COLOR_PLATFORM_NINTENDO;
    }

    &[data-platform="mobile"] {
        background-color: $COLOR_PLATFORM_MOBILE;
    }
}

.NewsCardListEmpty {
    margin-top: $SPACE_LARGER;

    &-title {
        color: $COLOR_ERROR;
        @include TYPOGRAPHY_H1;
    }
}

.NewsLoadMoreRow {
    margin-top: $SPACE_LARGE;
    margin-bottom: $SPACE_LARGE;
}

body {
    &.lang-ru {
        .PrimeResurgenceTabletMobilePanel{

            &-title{
                @include breakpoint-mobile-only{
                    font-size: 2.5rem;
                }
            }
            @include breakpoint-mobile-only{
                margin: 0;
                padding: 0;
            }
        }
    }
}
